<!-- model, color and upholstery prices -->

<ng-container *ngIf="showGeneralPrice && vehicleDetails">
  <div class="font-heading font-weight-bold mb-2 mt-2">
    {{ 'offer-detail.general-price' | translate }}
  </div>
  <div class="row justify-content-between">
    <div class="col-12 ms-2">
      <ng-container *ngIf="showModelPrice">
        <ucs-label-price [label]="'offer-detail.model-price' | translate"
                         [currency]="vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text"
                         [price]="vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.net"></ucs-label-price>
      </ng-container>
      <ng-container *ngIf="showColorPrice">
        <ucs-label-price [label]="'offer-detail.color-price' | translate"
                         [currency]="vehicleDetails.priceInformation['NEW_COLOR_PRICE']?.currency?.text"
                         [price]="vehicleDetails.priceInformation['NEW_COLOR_PRICE']?.net"></ucs-label-price>
      </ng-container>
      <ng-container *ngIf="showUpholsteryPrice">
        <ucs-label-price [label]="'offer-detail.upholstery-price' | translate"
                         [currency]="vehicleDetails.priceInformation['NEW_UPHOLSTERY_PRICE']?.currency?.text"
                         [price]="vehicleDetails.priceInformation['NEW_UPHOLSTERY_PRICE']?.net"></ucs-label-price>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- additional equipment prices (de: Mehrausstattungen) -->

<ng-container *ngIf="equipmentWithPrice && equipmentWithPrice.length > 0">
  <div class="font-heading font-weight-bold mb-2">
    {{ 'offer-detail.equipment-price' | translate }}
  </div>

  <ng-container *ngFor="let equipment of equipmentWithPrice; index as i;">
    <div class="row justify-content-between">
      <div class="col-12 ms-2">
        <div *ngIf="equipment; else showNetPriceUnavailableInfo">
          <ucs-label-price [label]="equipment.code + ' - ' + equipment.text" [currency]="equipment.price.currency.text"
                           [price]="equipment.price.net" [tooltipOverLabel]="equipment.detailText ? tooltipEquip : undefined"></ucs-label-price>
        </div>
        <ng-template #showNetPriceUnavailableInfo>
          <ucs-label-price [label]="equipment.code + ' - ' + equipment.text" [currency]="equipment.price.currency.text"
                           [price]="equipment.price.net" [tooltipOverLabel]="equipment.detailText ? tooltipEquip : undefined"
                           [showNetPriceUnavailableInfo]="true"></ucs-label-price>
        </ng-template>
        <popper-content #tooltipEquip>
          <div *ngIf="equipment.detailText">
            <div *ngFor="let equip of equipment.detailText.split('/#')" class="tooltip-styling">
              {{equip}}<br>
            </div>
          </div>
        </popper-content>
      </div>
    </div>
  </ng-container>

  <div class="row justify-content-between font-heading font-weight-bold">
    <div class="col-12 ms-2">
      <ucs-label-price [label]="'offer-detail.equipment-sum' | translate"
                       [currency]="equipmentWithPrice[0].price.currency.text"
                       [price]="sumEquipmentWithNetPrice" [sumIncalculable]="sumIncalculable"></ucs-label-price>
    </div>
  </div>
</ng-container>

<!-- Total price net -->

<ng-container *ngIf="showGeneralPrice || equipmentWithPrice && equipmentWithPrice.length > 0">
  <hr class="mt-0"/>

  <div class="row justify-content-between font-heading font-weight-bold">
    <div class="col-12 ms-2">
      <ucs-label-price [label]="'offer-detail.total-price-net' | translate"
                       [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
                       [price]="totalPriceNet"></ucs-label-price>
    </div>
  </div>
</ng-container>

<!-- NoVA rate & amount -->
<ng-container>
  <ng-container *ngIf="vehicleDetails.country === 'AT'">
    <ng-container *ngIf="(novaAmount >= 0 && !userState.isGerman)">
      <hr class="mt-0"/>

      <div class="row justify-content-between">
        <div class="col-12 ms-2">
          <ucs-label-price
            [label]="(vehicleDetails.extraTax?.rate/10000 | percent:'1.2') + ' ' + ('offer-detail.nova' | translate)"
            [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
            [price]="novaAmount"></ucs-label-price>
        </div>
      </div>
    </ng-container>

    <!-- NoVA discount surcharge (de: NoVA Zu-/Abschlag) -->

    <ng-container *ngIf="!ucsIsNil(vehicleDetails?.novaDiscountSurcharge)">
      <div class="row justify-content-between">
        <div class="col-12 ms-2">
          <ucs-label-price [label]="'offer-detail.nova-discount-surcharge' | translate"
                           [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
                           [price]="vehicleDetails?.novaDiscountSurcharge"></ucs-label-price>
        </div>
      </div>
    </ng-container>

    <!-- NoVA subtotal (de: Zwischensumme) -->

    <ng-container *ngIf="novaSubtotal">
      <div class="row justify-content-between font-heading font-weight-bold">
        <div class="col-12 ms-2">
          <ucs-label-price [label]="('offer-detail.nova-subtotal' | translate) + ' ' +
          (vehicleDetails.extraTax?.rate/10000 | percent:'1.2') + ' ' + ('offer-detail.nova' | translate)"
                           [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
                           [price]="novaSubtotal"></ucs-label-price>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<!-- VAT (de: USt.) -->

<ng-container *ngIf="vatAmount">
  <hr class="mt-0"/>

  <div class="row justify-content-between">
    <div class="col-12 ms-2">
      <ucs-label-price [label]="('offer-detail.vat' | translate) + ' ' + (vatFactor | percent)"
                       [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
                       [price]="vatAmount"></ucs-label-price>
    </div>
  </div>
</ng-container>

<!-- price total (incl. NoVA & VAT) -->

<ng-container *ngIf="totalPrice">
  <hr class="mt-0"/>

  <ng-container>
    <ng-container *ngIf="vehicleDetails.country === 'AT'; else vehicleNotFromAt">
      <div class="row justify-content-between font-heading font-weight-bold">
        <div class="col-12 ms-2">
          <ucs-label-price [label]="('offer-detail.total' | translate) + ' ' +
          (vehicleDetails.extraTax?.rate/10000 | percent:'1.2') + ' ' + ('offer-detail.nova' | translate) +  ' & ' +
          ('offer-detail.vat' | translate) + ' ' + (vatFactor | percent)"
                           [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          ('offer-detail.vat' | translate) + ' ' + (vatFactor | percent))"
                       [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
                           [price]="totalPrice" [sumIncalculable]="sumIncalculable"></ucs-label-price>
        </div>
      </div>
    </ng-container>

<!--    do not show nova for vehicles that are not from at-->
    <ng-template #vehicleNotFromAt>
      <div class="row justify-content-between font-heading font-weight-bold">
        <div class="col-12 ms-2">
          <ucs-label-price [label]="('offer-detail.total' | translate) + ' ' +
          ('offer-detail.vat' | translate) + ' ' + (vatFactor | percent)"
                           [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
                           [price]="totalPrice" [sumIncalculable]="sumIncalculable"></ucs-label-price>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
