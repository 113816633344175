<h2>{{ 'terms.terms' | translate }}</h2>

<!-- we remove DIN_BIL and VGRX because we actually dont have buyer users for this two channel so we dont need
 the AGBs -->
<ng-container *ngFor="let distributionChannel of ['PIA', 'PB', 'ALL_UC']">

  <ng-container *ngIf="userState.userInfo?.currentDealerChannelCountries[distributionChannel]?.length > 0">

    <h3>{{ 'ucs.' + distributionChannel.toLowerCase() | translate }}</h3>
    <ng-container *ngFor="let country of userState.userInfo.currentDealerChannelCountries[distributionChannel]">

      <strong>{{ 'api.type.country.' + country | translate }} ({{ country }})</strong><br>
      <a href="../../assets/gtc/{{ getGTCFile(distributionChannel, country)[0] }}" target="_blank">{{ 'terms.' + distributionChannel.toLowerCase() | translate }}</a>
      <ng-container *ngIf="getGTCFile(distributionChannel, country).length > 1">
        <br>
        <a href="../../assets/gtc/{{ getGTCFile(distributionChannel, country)[1] }}" target="_blank">{{ 'terms.' + distributionChannel.toLowerCase() | translate }} ({{ 'terms.english-version' | translate }})</a>
      </ng-container>
      <ng-container *ngIf="distributionChannel === 'PB' && country === 'AT'">
        <br>
        <a href="../../assets/gtc/PP_PB_AT_de_2024_04.pdf" target="_blank">{{ 'gtc-change-history-PB-v2.row12' | translate }}</a>
          <br>
          <a href="../../assets/gtc/PP_PB_AT_en_2024_04.pdf" target="_blank">{{ 'gtc-change-history-PB-v2.row13' | translate }}</a>
      </ng-container>
      <ng-container *ngIf="distributionChannel === 'PB' && country === 'SI'">
          <br>
          <a href="../../assets/gtc/PP_PB_SI_sl_2024_05.pdf" target="_blank">{{ 'gtc-change-history-PB-v2.row12' | translate }}</a>
      </ng-container>
      <br><br>
    </ng-container>

  </ng-container>

</ng-container>

<div class="spacer"></div>
