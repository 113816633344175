<div class="alert alert-info col col-12">
  <form [formGroup]="form" #gtcAcceptanceForm="ngForm" *ngIf="gtcs?.length > 0">
    <p><strong>{{ 'gtc.title' | translate }} {{ 'gtc.channel.' + gtcs[0].channel | translate }}</strong></p>

    <div *ngFor="let gtc of gtcs">
      <p><strong class="text-dark">{{ 'api.type.country.' + gtc.country | translate }} ({{gtc.country}})</strong></p>
      <div *ngIf="gtc.channel === 'PIA' && gtc.country === 'AT'">
        <p>{{ 'gtc.version' | translate }} {{ gtc.name }} {{ 'gtc.point' | translate }}</p>

        <ul>
          <li>{{"§"}} {{ 'gtc-change-history-PIA-v2_1' | translate }}</li>
        </ul>
      </div>
      <div *ngIf="gtc.channel === 'PB' && gtc.country === 'AT'">
        <p>{{ 'gtc-change-history-PB-v3.row1' | translate }}</p>
        <p>{{ 'gtc-change-history-PB-v3.row2' | translate }}</p>
        <p>{{ 'gtc-change-history-PB-v3.row3' | translate }}</p>
        <ul>
          <li>{{ 'gtc-change-history-PB-v3.row4' | translate }}</li>
        </ul>
        <p>{{ 'gtc-change-history-PB-v3.row5' | translate }}</p>
        <ul>
          <li>{{ 'gtc-change-history-PB-v3.row6' | translate }}</li>
        </ul>
        <p>{{ 'gtc-change-history-PB-v3.row8' | translate }}</p>
        <p>{{ 'gtc-change-history-PB-v3.row9' | translate }}</p>
        <p>{{ 'gtc-change-history-PB-v3.row10' | translate }}</p>
        <p>{{ 'gtc-change-history-PB-v3.row11' | translate }}</p>
      </div>
      <p>
        <input type="checkbox" id="{{gtc.channel}}-{{gtc.country}}" formControlName="{{gtc.channel}}-{{gtc.country}}" required>
        <label class="mb-2" for="{{gtc.channel}}-{{gtc.country}}"> {{ 'gtc.checkBox.pre' | translate }}
          <strong><a href="assets/gtc/{{ getGTCFile(gtc.channel, gtc.country)[0] }}" target="_blank">{{ (gtc.channel === 'PB' && gtc.country === 'AT') ? ('terms.pb.AT.v3' | translate) : ('terms.' + gtc.channel.toLowerCase() | translate) }}</a></strong>
          {{ 'gtc.checkBox.post' | translate }}
          <ng-container *ngIf="getGTCFile(gtc.channel, gtc.country).length > 1">
            (<strong><a href="../../assets/gtc/{{ getGTCFile(gtc.channel, gtc.country)[1] }}" target="_blank">{{ 'terms.english-version' | translate }}</a></strong>)
          </ng-container>
        </label>
      </p>
      <p *ngIf="gtc.channel === 'PB' && gtc.country === 'AT'">
        <strong><a href="assets/gtc/PP_PB_AT_de_2024_04.pdf" target="_blank">{{ 'gtc-change-history-PB-v2.row12' | translate }}</a>
            <br>
        <a href="assets/gtc/PP_PB_AT_en_2024_04.pdf" target="_blank">{{ 'gtc-change-history-PB-v2.row13' | translate }}</a></strong>
      </p>
      <br/>
    </div>
    <button class="btn accept-button" [disabled]="gtcAcceptanceForm.form.invalid" (click)="acceptAll()">
      <strong>{{'gtc.accept' | translate }}</strong>
    </button>
  </form>
</div>
