import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnforcedAuctionService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) { }

  editStartingPrice(catalogItemId: number, startingPrice: PriceDto) {
    return this.http.put(
      this.config.salesApiUrl + '/enforced-auction/catalog/current/items/' + catalogItemId + '/startingPrice', startingPrice);
  }

  setCatalogExpirationDate(expirationDate: Date){
    let params = new HttpParams();
    params = params.set('year', String(expirationDate.getFullYear()));
    params = params.set('month', String(expirationDate.getMonth() + 1));
    params = params.set('day', String(expirationDate.getDate()));
    return this.http.post(
      this.config.salesApiUrl + '/enforced-auction/catalog/current/expirationDate', '',{params: params});
  }

  setCatalogStatusReleased(){
    return this.http.post(
      this.config.salesApiUrl + '/enforced-auction/catalog/current/status/RELEASED','');
  }

  /**
   * Update enforced auction catalog item status (add/remove)
   */
  enforcedAuctionBookmark(dto: UpsertCatalogItemDto) {
    return this.http.put(
      this.config.salesApiUrl + '/enforced-auction/catalog/current/items/', dto);
  }

  getEnforcedVehicleDetails(vehicleId: number): Observable<EnforcedAuctionVehicleDetailDto> {
    return this.http.get<EnforcedAuctionVehicleDetailDto>(this.config.salesApiUrl + '/enforced-auction/vehicles/detail/' + vehicleId);
  }

  getEnforcedVehicleBase(vehicleId: number): Observable<EnforcedAuctionVehicleBaseDto> {
    return this.http.get<EnforcedAuctionVehicleBaseDto>(this.config.salesApiUrl + '/enforced-auction/vehicles/base/' + vehicleId);
  }

  persistEnforcedAuctionTransportMailRequestEntry(data: EnforcedAuctionTransportRequestMailDto) {
    return this.http.put(this.config.salesApiUrl + '/enforced-auction/transport-requests', data);
  }

  getPossibleCandidatesCount(filterSettings: EnforcedAuctionCatalogFilterSettingsDto) {
    let settings: EnforcedAuctionCatalogFilterSettingsDto = this.processEnforcedAuctionCatalogFilterSettingsNullValues(filterSettings);
    let params = new HttpParams();
    params = params.set('maxMarketValue', settings.maxMarketValue);
    params = params.set('maxPowerPs',settings.maxPowerPs);
    params = params.set('minPeriodSinceInitialRegistration',
      (<Date>settings.minPeriodSinceInitialRegistration).toJSON().substring(0,10));
    params = params.set('stationaryDaysLimit',settings.stationaryDaysLimit);

    return this.http.get<number>(this.config.salesApiUrl + '/enforced-auction/catalog/criteria/count',
      {params: params});
  }

  updateCatalogCriteria(filterSettings: EnforcedAuctionCatalogFilterSettingsDto){
    return this.http.post(this.config.salesApiUrl + '/enforced-auction/catalog/criteria',
      this.processEnforcedAuctionCatalogFilterSettingsNullValues(filterSettings));
  }

  private processEnforcedAuctionCatalogFilterSettingsNullValues(filterSettings: EnforcedAuctionCatalogFilterSettingsDto) {
    let setting: EnforcedAuctionCatalogFilterSettingsDto = structuredClone(filterSettings);
    setting.maxMarketValue = setting.maxMarketValue? setting.maxMarketValue : Number.MAX_SAFE_INTEGER;
    setting.maxPowerPs = setting.maxPowerPs? setting.maxPowerPs : Number.MAX_SAFE_INTEGER;
    setting.minPeriodSinceInitialRegistration =
      setting.minPeriodSinceInitialRegistration? setting.minPeriodSinceInitialRegistration : new Date();

    return setting;
  }
}
