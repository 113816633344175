<div *ngIf="userState.isAustrian || isNovaInfoGrossPriceEnabled ||
((this.nationalSale || this.scopeSection === 'SALES_MAINTENANCE'))">
  <div class="gross-price-container" [ngClass]="{'mb-2 not-calculable': !grossPrice}">
    <span *ngIf="!grossPrice">{{ 'tile-footer.gross-price.not-calculable' | translate }}</span>
    <span *ngIf="grossPrice" [ngStyle]="{'font-size': (grossPrice?.toString().length >= 9) ? '18px' : '22px' }">
      {{ grossPrice | price }} {{ currentPrice.currency.data }}</span>
    <span *ngIf="grossPrice" class="gross-net ms-1">{{ 'api.type.pricetype.GROSS' | translate }}</span>
  </div>

  <div *ngIf="grossPrice" class="row mb-2 icons">
    <ucs-calc-icon class="col-auto" *ngIf="vatType === 'STANDARD' && (userState.isAustrian || channel === 'ALL_UC')" [type]="'OK'" [text]="'icon.vat.DIFFERENTIAL'"></ucs-calc-icon>
    <ucs-calc-icon class="col-auto" *ngIf="(userState.isAustrian || isNovaInfoGrossPriceEnabled) && (offerType !== 'ENFORCED_AUCTION')"
                   [type]="extraTax?.paid ? 'OK' : (['PIA', 'PB', 'DIN_BIL', 'VGRX'].includes(channel) ? 'NOK' : 'MANUAL')"
                   [text]="'icon.nova.' + (extraTax?.paid ? 'OK' : (['PIA', 'PB', 'DIN_BIL' , 'VGRX'].includes(channel) ? 'NOK' : 'MANUAL'))"></ucs-calc-icon>
  </div>
</div>
