import {Component, Input, OnChanges} from '@angular/core';
import {TaxService} from '../../../service/tax.service';
import {State} from '../../../store/user/user.reducers';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {first} from 'rxjs/operators';
import {SystemSettingsService} from '../../../service/system-settings.service';

@Component({
  selector: 'ucs-gross-price',
  templateUrl: './gross-price.component.html',
  styleUrls: ['./gross-price.component.scss']
})
export class GrossPriceComponent implements OnChanges {
  @Input() currentPrice: PriceDto;
  @Input() vatType: VatType;
  @Input() extraTax: ExtraTaxDto;
  @Input() channel: DistributionChannel;
  @Input() nationalSale: boolean;
  @Input() scopeSection: AppScope;
  @Input() offerType: OfferType;

  grossPrice: number;
  userState: State;
  isNovaInfoGrossPriceEnabled: boolean;

  constructor(private taxService: TaxService, private store: Store<fromRoot.AppState>,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnChanges(): void {
    this.store
      .select(fromRoot.getUserState)
      .pipe(first())
      .subscribe(userState => {
        if (userState) { // wait for user state before we process because we need the country for price calculations
          this.userState = userState;
          if (this.userState.isAustrian || this.userState.isGerman ||
            ((this.nationalSale || this.scopeSection === 'SALES_MAINTENANCE'))) {
            if (!this.currentPrice?.gross && this.currentPrice?.gross !== 0) {
              this.taxService
                .getGrossPriceWithExtraTax(
                  this.currentPrice?.net, this.vatType, this.extraTax?.paid ? 0 : this.extraTax?.rate,
                  this.extraTax?.amount, <Country>this.userState.userInfo.country, this.channel
                ).subscribe(grossValue => this.grossPrice = grossValue);
            } else {
              this.grossPrice = this.currentPrice.gross;
            }
          } else {
            this.grossPrice = undefined;
          }
        }
      });

    this.systemSettingsService
      .isSystemFeatureActivatedForChannel('SHOW_NOVA_INFO_GROSS_PRICE_TO_FOREIGN_DEALERS', this.channel)
      .pipe(first())
      .subscribe(enabled => {
        this.isNovaInfoGrossPriceEnabled = enabled;
      });
  }
}
