<ng-container *ngIf="logs.length > 0; else hideTable">
  <table class="table table-responsive text mt-2">
      <caption>Audit log</caption>
    <thead>
    <tr>
      <th scope="col"><span>{{ 'audit-log.table.date' | translate }}
                </span></th>
      <th scope="col"><span>{{ 'audit-log.table.actor' | translate }}
                </span></th>
      <th scope="col"><span>{{ 'audit-log.table.action' | translate }}
                </span></th>
      <th scope="col"><span>{{ 'audit-log.table.comment' | translate }}
                </span></th>
      <th scope="col"><span>{{ 'audit-log.table.diff' | translate }}
                </span></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let log of logs" class="mt-3">
      <!-- date -->
      <td>{{log.timestamp | date : 'dd.MM.yyyy HH:mm:ss'}}</td>
      <!-- actor -->
      <td>
        <span *ngIf="log.dealer">{{log.actor + " / " + log.dealer}}</span>
        <span *ngIf="!log.dealer">{{log.actor}}</span>
      </td>
      <!-- action -->
      <td>
        <span *ngIf="!log.oldValue && log.action === 'OFFER_EDITABLE_FIELD_MODIFICATION'">
        {{'audit-log.action.OFFER_EDITABLE_FIELDS_SET' | translate }} </span>
        <span *ngIf="!log.oldValue && log.action === 'VEHICLE_EDITABLE_FIELDS_MODIFICATION'">
        {{'audit-log.action.VEHICLE_EDITABLE_FIELDS_SET' | translate }}</span>
        <span *ngIf="!(log.action === 'OFFER_EDITABLE_FIELD_MODIFICATION' ||
        log.action === 'VEHICLE_EDITABLE_FIELDS_MODIFICATION') || (log.oldValue && log.newValue) ||
        (log.oldValue && !log.newValue)">
        {{'audit-log.action.' + log.action | translate }}</span>
      </td>
      <!-- comment -->
      <td>
        <span *ngIf="!(log.action === 'VEHICLE_DOCUMENT_ADD' || log.action === 'VEHICLE_DOCUMENT_DELETE')
        && !isStatusChangeOrCreation(log)">{{log.comment}}</span>
        <span *ngIf="isStatusChangeOrCreation(log)">{{log.comment.split('§')[1]}}</span>
      </td>
      <!-- diff -->
      <td>
        <span *ngIf="isStatusChangeOrCreation(log)">{{log.comment.split('§')[0]}}</span>
        <span *ngIf="log.action === 'VEHICLE_EXTRA_TAX_PAID_CHANGE'">
          {{'audit-log.value.VEHICLE_EXTRA_TAX_PAID_CHANGE.' + log.oldValue | translate}} ->
          {{'audit-log.value.VEHICLE_EXTRA_TAX_PAID_CHANGE.' + log.newValue | translate}}</span>
        <span *ngIf="log.property && log.oldValue.length === 0">{{'(' + log.property + ') ' + log.newValue}}</span>
        <span *ngIf="log.property && log.oldValue.length > 0">{{'(' + log.property + ') ' + log.oldValue + ' -> ' +
        log.newValue}}</span>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>

<ng-template #hideTable><strong class="row ms-auto">{{'gdpr.noData' | translate}}</strong></ng-template>
