import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HtmlSanitizerService} from '../../../service/html-sanitizer.service';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead/typeahead-match.class';
import {ucsIsNil} from '../../../misc/utils';

/**
 * This component encapsulates information on the dealer
 */
@Component({
  selector: 'ucs-vehicle-seller',
  templateUrl: './vehicle-seller.component.html',
  styleUrls: ['./vehicle-seller.component.scss']
})
export class VehicleSellerComponent implements OnInit {
  @Input() isTop: boolean;
  @Input() vehicle: VehicleDetailDto;
  @Input() vehicleDetailUpdate: VehicleDetailUpdateDto;
  @Input() showTop: boolean;
  @Input() employees: UserDto[];
  @Input() isAccountingClerk: boolean;
  @Output() employeeSelected = new EventEmitter<number>();
  selectedEmployee: UserDto;
  selectedValue = '';
  employeesName: any[] = [];

  protected readonly ucsIsNil = ucsIsNil;

  ngOnInit(): void {
    const myAggregatedStates = [];
    this.employees.forEach(value => myAggregatedStates.push({employe: value, fullname: value.lastName+' '+value.firstName}));
    this.employeesName = myAggregatedStates;

    if (this.isAccountingClerk) {
      this.selectedEmployee = this.employees.find(e => e.id === this.vehicleDetailUpdate.accountingClerkUserId);
      if(!ucsIsNil(this.selectedEmployee)) {
        this.selectedValue = this.selectedEmployee?.lastName+' '+this.selectedEmployee?.firstName;
      }
    } else {
      this.selectedEmployee = this.employees.find(e => e.id === this.vehicleDetailUpdate.contactPersonId);
    }
  }

  onSelect(event: TypeaheadMatch) {
    this.selectedEmployee = event.item.employe;

    if (this.isAccountingClerk) {
      this.vehicleDetailUpdate.accountingClerkUserId = this.selectedEmployee.id;
      this.employeeSelected.emit(this.vehicleDetailUpdate.accountingClerkUserId);
    } else {
      this.vehicleDetailUpdate.contactPersonId = this.selectedEmployee.id;
      this.employeeSelected.emit(this.vehicleDetailUpdate.contactPersonId);
    }
  }

  onEmployeeSelect($event: UserDto) {
    this.vehicleDetailUpdate.contactPersonId = +$event;
    this.selectedEmployee = this.employees.find(e => e.id === this.vehicleDetailUpdate.contactPersonId);
    this.employeeSelected.emit(this.vehicleDetailUpdate.contactPersonId);
  }

  validateEmailSubject(text: string) {
    return HtmlSanitizerService.sanitize(text);
  }

  onFocus() {
    if ((document['documentMode'] && !this.selectedValue) ||
        (this.selectedValue && this.selectedValue.length === 0)) {
      this.selectedValue = ' ';
    }
  }
}
