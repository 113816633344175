<ucs-gross-profit *ngIf="grossProfitData" [grossProfitData]="grossProfitData"
                  class="ucs-gross-profit w-100 d-inline-block text-nowrap {{showGrossProfit === true ? '' : ' invisible'}}">
</ucs-gross-profit>

<div class="row gx-1 w-100 d-inline-block text-nowrap tax">
  <ucs-icon [shape]="vatType === 'STANDARD' ? 'info' : 'alert-triangle'" [size]="18"
            [class]="'grey'"></ucs-icon>
  <span class="ms-1 text-nowrap">{{ ('tile-footer.tax-nova.tax.' + vatType) | translate }}</span>
  <span *ngIf="showVatValue && vatType === 'STANDARD'" class="ms-1 text-nowrap">({{vatValue}}%)</span>
</div>

<ng-container *ngIf="nationalSale || isNovaInfoGrossPriceEnabled">
  <div *ngIf="country !== 'HR' || (country === 'HR' && nationalSale && extraTax?.paid)"
    class="row gx-1 w-100 d-inline-block text-nowrap nova">
    <ng-container *ngIf="country !== 'HR'">
      <ucs-icon [shape]="extraTax?.paid ? 'info' : 'alert-triangle'" [size]="18" [class]="'grey'"></ucs-icon>
      <span class="ms-1 text-nowrap">{{ (extraTax?.paid ? 'tile-footer.tax-nova.nova.nova-paid' : 'tile-footer.tax-nova.nova.nova-not-paid') | translate }}</span>
    </ng-container>
    <ng-container *ngIf="country === 'HR' && nationalSale && extraTax?.paid">
      <ucs-icon [shape]="'info'" [size]="18" [class]="'grey'"></ucs-icon>
      <span class="ms-1 text-nowrap">{{ 'vehicle-detail.extra-tax-label.PPMV' | translate }} {{extraTax?.amount | price}} {{currency}}</span>
    </ng-container>
  </div>
</ng-container>

<div *ngIf="vehicleOfferItem.thermalWindowAffected && showThermalWindowAffected && isThermalWindowAffectedEnabled && scopeSection === 'SALES_STORE'" class="row gx-1 w-100 d-inline-block text-nowrap tax">
  <ucs-icon [shape]="'alert-triangle'" [size]="18" [class]="'grey'"></ucs-icon>
  <span class="ms-1 text-nowrap">{{ 'vehicle-maintenance.detail.thermal-window' | translate }}</span>
</div>
