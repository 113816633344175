<div class="modal fade lineheight" id="{{'confirmBidModal' + offer.id}}" aria-labelledby="confirmBidModal"
     aria-hidden="true"
     tabindex="-1"
     role="dialog">
  <div class="modal-dialog modal-size" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'bid.dialog.head' | translate }}</h5>
        <button type="button" class="btn-close btn-close-white" (click)="cancel()" data-bs-dismiss="modal" aria-label="Close" data-cy="bid-dialog-close-button">
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div class="modal-body">
        <div class="d-lg-flex d-grid justify-content-between mb-3">
          <div class="title">
            <strong>{{offer.title}}</strong>
          </div>
            <ucs-logo-icon [distributionChannel]="offer.channel.data"
                           [country]="offer.country"
                           [dialog]="true">
            </ucs-logo-icon>
        </div>
        <div class="d-lg-flex d-grid justify-content-between mb-3 gap-2">
          <div>
                <ucs-icon [shape]="'location_pin'" [class]="'black'" [size]="16"></ucs-icon>
                <span class="mx-2">{{ offer.location }}</span>
                <ucs-icon [shape]="'flags/' + offer.country.toLowerCase()" [size]="18"></ucs-icon>
          </div>
          <div class="col-auto">
            <div class="tax">
              <ucs-icon [shape]="vehicleItem.vatType.data === 'STANDARD' ? 'info' : 'alert-triangle'" [size]="16"
                        [class]="'grey pe-2'"></ucs-icon>
              <span>{{ ('tile-footer.tax-nova.tax.' + vehicleItem.vatType.data) | translate }}</span>
            </div>
            <div *ngIf="userState.isAustrian || isNovaInfoGrossPriceEnabled" class="nova">
              <ucs-icon [shape]="vehicleItem.extraTax?.paid ? 'info' : 'alert-triangle'" [size]="16"
                        [class]="'grey pe-2'"></ucs-icon>
              <span>{{ (vehicleItem.extraTax?.paid ? 'tile-footer.tax-nova.nova.nova-paid' : 'tile-footer.tax-nova.nova.nova-not-paid') | translate }}</span>
            </div>
            <div *ngIf="isThermalWindowAffectedEnabled && showThermalWindowAffected && vehicleItem.thermalWindowAffected" class="tax">
              <ucs-icon [shape]="'alert-triangle'" [size]="16" [class]="'grey pe-2'"></ucs-icon>
              <span>{{ 'vehicle-maintenance.detail.thermal-window' | translate }}</span>
            </div>
          </div>
        </div>

        <div class="d-lg-flex d-grid">
          <span class="col-12 col-sm-6 minimum-bid">
            <strong>{{ 'bid.dialog.minimum-bid' | translate }}:</strong>
            {{ offer?.minimumBid?.net | price: offer?.minimumBid?.currency?.text }}{{ (offer?.country === 'UA')? '*':'' }}
            {{ 'api.type.pricetype.NET' | translate }}</span>
          <span *ngIf="displayMinimumPurchasePrice" class="col-auto guaranteed-from">
            <strong>{{ 'bid.dialog.guaranteed-from' | translate }}:</strong>
            {{ offer?.minimumPurchasePrice?.net | price: offer?.minimumPurchasePrice?.currency?.text}}{{ (offer?.country === 'UA')? '*':'' }}
            {{ 'api.type.pricetype.NET' | translate }}
          </span>
        </div>

        <div *ngIf="isShowNovaRefundEnabled && novaRefundTotalSum !== null && (userState?.userInfo?.country !== 'AT') && offer.country !== userState?.userInfo?.country" class="row">
            <span class="col-6 minimum-bid">
              <strong>{{('offer-detail.nova-refund' | translate) + ':'}}</strong>
              {{ (novaRefundTotalSum | price: offer?.minimumBid?.currency?.text ? offer.minimumBid.currency.text : ' EUR') }}
            </span>
            <span *ngIf="offer.items.length > 1">&nbsp;{{' (' + ('offer-detail.nova-refund.total' | translate) + ')'}}</span>
        </div>

        <div *ngIf="vehicleItem.infoText" class="d-flex justify-content-between">
          <div class="info-overflow">
            <strong>{{'bid.dialog.info' | translate}}</strong>
            {{ (vehicleItem.infoText.length > 72)?
                  (vehicleItem.infoText | truncate
                          : 70 - ('bid.dialog.info'| translate)?.length - ('bid.dialog.show' | translate)?.length)
                          : vehicleItem.infoText}}
          </div>
          <span *ngIf="vehicleItem.infoText.length > 72"
                class="overflow-link fw-bold"
                #popOver="ngbPopover"
                [ngClass]="{'overflow-link-hover': popOver.isOpen()}"
                [ngbPopover]="popOverInfo"
                autoClose="true"
                placement="bottom-right">{{ 'bid.dialog.show' | translate }}
          </span>
        </div>
        <ng-template #popOverInfo>
          <span>{{ vehicleItem.infoText | truncate
                  : 70 - ('bid.dialog.info'| translate)?.length - ('bid.dialog.show' | translate)?.length
                  :'prefix' }}</span>
        </ng-template>
        <div class="row mt-2 d-flex justify-content-between">
          <strong class="col-3 align-self-end">{{ 'bid.dialog.your-bid' | translate }}</strong>
          <ucs-bid-status class="col-auto" *ngIf="['AUCTION','ENFORCED_AUCTION'].includes(offer?.offerType?.data) && offer?.eligible === true"
                          [status]="offer?.bidStatus?.data"
                          [offerStatus]="offer?.status?.data"
                          [maximumBid]="offer?.maximumBid"
                          [myLastHighestBid]="offer?.myLastHighestBid"
                          [myLastMaximumBid]="offer?.myLastMaximumBid"
                          [tooLateBid]="offer?.tooLateBid"
                          [purchasePrice]="offer?.purchase?.price"
                          [highestBid]="offer?.highestBid"
                          [purchaseReceived]="offer?.purchase?.purchaseReceived"
                          [nationalSale]="offer?.nationalSale"
                          [isUAOffer]="offer?.country === 'UA'"></ucs-bid-status>
        </div>

        <form>
          <ucs-net-gross-input [netPrice]="bidPrice"
                               [grossPrice]="bidPriceGross"
                               [id]="offer.id.toString()"
                               [scope]="'OFFER'"
                               [sectionScope]="'SALES_STORE'"
                               [showVatNovaIcons]="true"
                               [currency]="offer.startPrice?.currency.data"
                               [isUAOffer]="offer?.country === 'UA'"
                               [netPriceValidationError]="validationError"
                               [grossPriceValidationError]="grossPriceValidationError"
                               [hideGrossPrice]="!offer.nationalSale"
                               [headingTranslationKey]="''"
                               [vehicleOrOfferDto]="offer"
                               [priceInputDisabled]="evaluatePriceInputDisabled()"
                               (backGrossPriceChange)="bidPriceGross = $event"
                               (backNetPriceChange)="bidPrice = $event"
                               (netOrGross)="userFocus($event)"
                               (inputErrorOutput)="getInputError($event)" (enterOnInputElement)="bid()">
          </ucs-net-gross-input>

          <div class="d-lg-flex d-grid justify-content-between gap-2">
            <div class="d-grid col-12 col-sm-5">
                <input [(ngModel)]="bidRequest.bidType"
                       class="mt-1"
                       [id]="offer.id + '_bid-type-maximum'"
                       data-cy="maximum-bid-type-selection"
                       type="radio"
                       name="bid-type-selection"
                       [value]="'MAXIMUM'">
                <label [for]="offer.id + '_bid-type-maximum'" class="bid-type ms-1">
                  {{ 'bid.dialog.maximum-bid' | translate }}
                </label>
                <input [(ngModel)]="bidRequest.bidType"
                       class="mt-1"
                       [id]="offer.id + '_bid-type-regular'"
                       data-cy="regular-bid-type-selection"
                       type="radio"
                       name="bid-type-selection"
                       [value]="'REGULAR'"
                       (click)="onRegularBidSelect()">
                <label [for]="offer.id + '_bid-type-regular'" class="bid-type ms-1">
                  {{ 'bid.dialog.regular-bid' | translate }}
                    <button type="button" class="btn-popover" [popper]="('bid-dialog.regular-bid.info' | translate)"
                            [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperApplyClass]="'info-popover-size'">
                      <ucs-icon [shape]="'info'" [size]="18" [class]="'blue'"></ucs-icon>
                    </button>
                </label>
            </div>
            <div class="text-binding">
              <em><strong>{{'bid-dialog.attention' | translate }}:</strong> {{ 'bid-dialog.text-binding' | translate }}</em>
            </div>
          </div>
        </form>
        <div *ngIf="(offer?.country === 'UA')" class="d-flex justify-content-between pt-3">
          <div class="info-overflow">
            <strong>{{'bid.dialog.disclaimer.title'| translate}}</strong>
            {{ 'bid.dialog.disclaimer.text'| translate | truncate
                  : 66 - ('bid.dialog.disclaimer.title'| translate)?.length - ('bid.dialog.show' | translate)?.length}}
          </div>
          <span class="overflow-link fw-bold"
                #popOver="ngbPopover"
                [ngClass]="{'overflow-link-hover': popOver.isOpen()}"
                [ngbPopover]="popOverDisclaimer"
                autoClose="true"
                placement="bottom-right">{{ 'bid.dialog.show' | translate }}
          </span>
        </div>
        <ng-template #popOverDisclaimer>
          <span>{{ 'bid.dialog.disclaimer.text'| translate | truncate
                  : 66 - ('bid.dialog.disclaimer.title'| translate)?.length - ('bid.dialog.show' | translate)?.length
                  :'prefix' }}</span>
        </ng-template>
      </div>

      <div class="modal-footer"  (keydown)="onFooterKeyDown($event)" tabindex="-1">
        <ucs-countdown class="me-auto" [expirationDate]="offer?.expiration" [isBidDialog]="true"></ucs-countdown>
        <button type="button" class="btn btn-secondary button-cancel" (click)="cancel()" data-bs-dismiss="modal">
          {{'bid.dialog.cancel' | translate }}
        </button>
        <button type="button" class="btn btn-primary button-save" data-cy="bid-save" [disabled]="inputError || isExpired" (click)="bid()" #bidButton>
          {{'bid.dialog.bid' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
